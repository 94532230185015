var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("ul", { staticClass: "tab-c flex f-jc-sa f-ai-c" }, [
      _c(
        "li",
        {
          class: _vm.activeTab === 1 ? "active" : "",
          on: {
            click: function ($event) {
              return _vm.tabClick(1)
            },
          },
        },
        [_vm._v("医生信息")]
      ),
      _c(
        "li",
        {
          class: _vm.activeTab === 2 ? "active" : "",
          on: {
            click: function ($event) {
              return _vm.tabClick(2)
            },
          },
        },
        [_vm._v("问诊对话")]
      ),
      _c(
        "li",
        {
          class: _vm.activeTab === 3 ? "active" : "",
          on: {
            click: function ($event) {
              return _vm.tabClick(3)
            },
          },
        },
        [_vm._v("我的病历")]
      ),
    ]),
    _c(
      "div",
      { style: { overflow: _vm.activeTab === 2 ? "hidden" : "auto" } },
      [
        _vm.activeTab === 1
          ? _c("doctor-detail", {
              on: {
                refresh: function ($event) {
                  return _vm.tabClick(2)
                },
              },
            })
          : _vm._e(),
        _vm.activeTab === 2
          ? _c("s-im", { attrs: { soughtType: _vm.soughtType } })
          : _vm._e(),
        _vm.activeTab === 3 ? _c("medical-record") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }