var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-content" }, [
    !_vm.dataList.length
      ? _c("div", { staticClass: "no-data flex f-jc-c f-ai-c" }, [_vm._m(0)])
      : _c(
          "div",
          { staticClass: "wrap" },
          _vm._l(_vm.dataList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "item",
                on: {
                  click: function ($event) {
                    return _vm.gotoDetail(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "item-top" }, [
                  _c("span", { staticClass: "patient" }, [
                    _c("i", { staticClass: "item-icon" }),
                    _c("label", [_vm._v(_vm._s(item.patientName))]),
                    _c("i"),
                    _c("span", [
                      _vm._v(_vm._s(item.gender === 1 ? "男" : "女")),
                    ]),
                    _c("i"),
                    _c("span", [_vm._v(_vm._s(item.age) + "岁")]),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "status",
                      style: {
                        color:
                          item.status === 1
                            ? "#FF8D16"
                            : item.status === 2
                            ? "#09BB07"
                            : item.status === 3
                            ? "#09BB07"
                            : "#BEBEBE",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.status === 1
                              ? "待支付"
                              : item.status === 2
                              ? "待收货"
                              : item.status === 3
                              ? "已完成"
                              : item.status === 4
                              ? "已取消"
                              : item.status === 5
                              ? "待发货"
                              : item.status === 6
                              ? "已退款"
                              : "未知状态"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "item-center" }, [
                  _c("div", [
                    _c("span", [_vm._v("医生：")]),
                    _c("span", [_vm._v(_vm._s(item.doctorName))]),
                    _c("span", [_vm._v(_vm._s(item.deptName))]),
                    _c("span", [_vm._v(_vm._s(item.titleName))]),
                  ]),
                  _c("div", [
                    _c("span", [_vm._v("诊断：")]),
                    _c("span", [_vm._v(_vm._s(_vm.cutdownStr(item.zyzd, 20)))]),
                  ]),
                ]),
                _c("div", { staticClass: "item-bottom" }, [
                  _c("span", [_vm._v(_vm._s(_vm.formatTime(item.createdAt)))]),
                  _vm._m(1, true),
                ]),
              ]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex f-d-c f-ai-c" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/no-data.png"), alt: "" },
      }),
      _c("span", [_vm._v("暂无数据")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "see" }, [
      _c("label", [_vm._v("查看详情")]),
      _c("i"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }