<template>
	<div class="wrap-content">
		<div v-if="!dataList.length" class="no-data flex f-jc-c f-ai-c">
			<div class="flex f-d-c f-ai-c">
				<img src="../../assets/images/no-data.png" alt="">
				<span>暂无数据</span>
			</div>
		</div>
		<div v-else class="wrap">
			<div @click="gotoDetail(item)" v-for="(item, index) in dataList" :key="index" class="item">
				<div class="item-top">
					<span class="patient">
						<i class="item-icon"></i>
						<label>{{item.patientName}}</label>
						<i></i>
						<span>{{item.gender === 1 ? '男' : '女'}}</span>
						<i></i>
						<span>{{item.age}}岁</span>
					</span>
					<span class="status" :style="{ color: item.status === 1 ? '#FF8D16' : item.status === 2 ? '#09BB07' : item.status === 3 ? '#09BB07' : '#BEBEBE' }">
						{{
							item.status === 1 ? '待支付' :
							item.status === 2 ? '待收货' :
							item.status === 3 ? '已完成' :
							item.status === 4 ? '已取消' :
							item.status === 5 ? '待发货' :
							item.status === 6 ? '已退款' :
							'未知状态'
						}}
					</span>
				</div>
				<div class="item-center">
					<div>
						<span>医生：</span>
						<span>{{item.doctorName}}</span>
						<span>{{item.deptName}}</span>
						<span>{{item.titleName}}</span>
					</div>
					<div>
						<span>诊断：</span>
						<span>{{cutdownStr(item.zyzd, 20)}}</span>
					</div>
				</div>
				<div class="item-bottom">
					<span>{{formatTime(item.createdAt)}}</span>
					<span class="see"><label>查看详情</label><i></i></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { medicalRecordList } from '@/request/api/user';
import { dateFormat } from '@/utils/time';
import { cutdownString } from '@/utils';

export default {
	name: 'medicalRecord',
	data () {
		return {
			dataList: []
		};
	},
	created () {
		this.getDataList();
	},
	methods: {
		formatTime (time) {
			return dateFormat(new Date(time), 'yyyy年MM月dd日 hh:mm');
		},
		cutdownStr (str, length) {
			if (!str) str = '';
			return cutdownString(str, length);
		},
		getDataList () {
			this.dataList = [];
			medicalRecordList().then(data => {
				// console.log('data===', data);
				if (data && data === 'retry') {
					this.getDataList();
				} else if (data) {
					this.dataList = [...data];
				}
			});
		},
		gotoDetail (item) {
			// console.log('item======', item);
			// this.$toast('查看病历详情');
			this.$root.go(`/prescription-detail?id=${item.prescriptionId}`);
		}
	}
};
</script>
<style lang="less" scoped>
	.wrap-content {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.wrap {
		display: flex;
		flex-direction: column;
		padding-bottom: 10px;
	}
	.no-data {
		height: 300px;
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8B8B8B;
		}
	}
	.item {
		background: #FFFFFF;
		box-shadow: 0px 4px 17px -4px rgba(16,31,46,0.04);
		border-radius: 4px;
		// padding: 19px 14px 14px 13px;
		padding: 19px 14px 14px 0;
		position: relative;
		margin-top: 10px;
		.item-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.patient {
				display: flex;
				align-items: center;
				label {
					font-size: 15px;
					font-weight: 500;
					color: #232830;
					line-height: 13px;
					margin-left: 10px;
				}
				i {
					width: 1px;
					height: 9px;
					background: #F3F3F3;
					border-radius: 0px;
					margin-left: 7px;
				}
				i.item-icon {
					width: 4px;
					height: 12px;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
					border-radius: 2px;
					display: block;
					margin-left: 0;
					// position: absolute;
					// left: 0;
				}
				span {
					font-size: 12px;
					font-weight: 400;
					color: #959595;
					line-height: 12px;
					margin-left: 7px;
				}
			}
			.status {
				font-size: 14px;
				font-weight: 400;
				color: #FF8D16;
				line-height: 14px;
			}
		}
		.item-center {
			margin-top: 16px;
			padding-bottom: 14px;
			border-bottom: 1px solid #f3f3f3;
			margin-left: 14px;
			div {
				margin-top: 8px;
				span {
					font-size: 13px;
					font-weight: 400;
					color: #3B3D40;
					line-height: 13px;
					margin-left: 8px;
				}
				span:first-child {
					font-size: 13px;
					font-weight: 400;
					color: #7D7D7D;
					line-height: 13px;
					margin-left: 0;
				}
			}
		}
		.item-bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 10px;
			padding-left: 14px;
			span {
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #959595;
				line-height: 12px;
				display: flex;
				align-items: center;
				i {
					width: 9px;
					height: 9px;
					background: url('../../assets/images/right-arrow.png') no-repeat center center;
					background-size: auto 100%;
					margin-left: 2px;
					display: inline-block;
				}
			}
		}
	}
</style>