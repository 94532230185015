<template>
	<div class="msg-content">
		<!-- 我是IM 页面, 服务类型： {{soughtType}} -->
		<div class="content-top">
			<i v-if="detailData.status === 1" />
			<span class="status">{{detailData.status === 1 ? '问诊中' : detailData.status === 0 ? '已结束' : ''}}</span>
			<span v-if="detailData.type === 12 && detailData.status === 1">第{{detailData.rounds}}/{{detailData.totalRounds}}次</span>
			<span v-if="detailData.type === 11 && detailData.status === 1">倒计时：{{timeStr}}</span>
		</div>
		<van-pull-refresh ref="msgList" class="msg-list" :loosing-text="this.finished ? '已无更多数据' : '下拉加载更多'" pulling-text="下拉加载更多" v-model="refreshing" @refresh="onRefresh">
			<van-list
				:finished="finished"
				ref="msgList"
				class="msg-list"
				finished-text="请您耐心等待，接诊后，您可与医生直接沟通"
				offset="0"
				direction="up"
				:immediate-check="false"
			>
				<!-- <div class="msg-list" ref="msgList"> -->
					<!-- <div class="loadText">{{loadText}}</div> -->
					<div class="msg-item" v-for="(item, index) in dataList" :key="index">
						<!-- 我发送的信息 -->
						<div class="time">
							<span>{{formatDate(item.createdAt)}}</span>
						</div>
						<div v-if="(userInfo.mobilePhone === item.from) || (item.from === 'ODAT-PATIENT-' + userInfo.mobilePhone)" class="my-msg item-content">
							<!-- 卡片信息 -- 问诊单 -->
							<div @click="handleConsultation(item)" v-if="item.MsgContent.MsgType === 'TIMCustomElem' && item.MsgContent.MsgContent.type === 'Consultation'" class="consultation">
								<div class="consultation-top">
									<span>{{item.MsgContent.MsgContent.data.patientName}}</span>
									<i></i>
									<span>{{item.MsgContent.MsgContent.data.patientGender === 1 ? '男' : '女'}}</span>
									<i></i>
									<span>{{item.MsgContent.MsgContent.data.patientAge}}岁</span>
								</div>
								<div class="consultation-bottom">
									<div class="consultation-bottom-center">
										<span>病情描述：</span>
										<div>
											<span>{{cutdownStr(item.MsgContent.MsgContent.data.diseaseDescription, 20)}}</span>
										</div>
									</div>
									<div class="prescription-bottom">
										<span>查看问诊详情</span>
										<i></i>
									</div>
								</div>
							</div>
							<!-- 图片信息 -->
							<div v-else-if="item.MsgContent.MsgType === 'TIMImageElem'" class="message-content img-content">
								<div class="img-content" @click="handelClickImg(item.MsgContent.MsgContent.Image || item.MsgContent.MsgContent.Url)">
									<img :src="item.MsgContent.MsgContent.Image || item.MsgContent.MsgContent.Url + `?x-oss-process=image/resize,w_100`" />
								</div>
							</div>
							<div v-else-if="item.MsgContent.MsgType === 'TIMTextElem'" class="message-content">
								<div>{{item.MsgContent.MsgContent.Text}}</div>
							</div>
							<i class="avatar my-i"></i>
						</div>
						<!-- 医生发送的信息 -->
						<div v-else class="other-msg item-content">
							<i class="avatar doctor-i"></i>
							<!-- 卡片信息 -- 处方单 -->
							<div @click="handlePrescription(item.MsgContent.MsgContent.data.prescriptionId)" v-if="item.MsgContent.MsgType === 'TIMCustomElem' && item.MsgContent.MsgContent.type === 'Prescription'" class="prescription">
								<div class="prescription-top">
									<i class="disease-icon"></i>
									<span>{{cutdownStr(item.MsgContent.MsgContent.data.title, 10)}}</span>
									<i class="status-icon-1" v-if="+item.MsgContent.MsgContent.data.status === 1"></i>
									<i class="status-icon-2" v-if="+item.MsgContent.MsgContent.data.status === 2"></i>
									<i class="status-icon-3" v-if="+item.MsgContent.MsgContent.data.status === 3"></i>
									<i class="status-icon-4" v-if="+item.MsgContent.MsgContent.data.status === 4"></i>
									<i class="status-icon-5" v-if="+item.MsgContent.MsgContent.data.status === 5"></i>
									<i class="status-icon-6" v-if="+item.MsgContent.MsgContent.data.status === 6"></i>
								</div>
								<div class="prescription-bottom">
									<span>查看处方详情</span>
									<i></i>
								</div>
							</div>
							<div v-else-if="item.MsgContent.MsgType === 'TIMTextElem'" class="message-content">
								<div>{{item.MsgContent.MsgContent.Text}}</div>
							</div>
							<div v-else-if="item.MsgContent.MsgType === 'TIMImageElem'" class="message-content img-content">
								<div class="img-content" @click="handelClickImg(item.MsgContent.MsgContent.Image || item.MsgContent.MsgContent.Url)">
									<img :src="item.MsgContent.MsgContent.Image || item.MsgContent.MsgContent.Url + `?x-oss-process=image/resize,w_100` || item.MsgContent.MsgContent.Url" @error="onError" />
								</div>
							</div>
							<div v-else-if="item.MsgContent.MsgType === 'TIMSoundElem'" class="message-content sound-content">
								<audio controls controlsList="nodownload" :src="item.MsgContent.MsgContent.Url"></audio>
							</div>
						</div>
					</div>
				<!-- </div> -->
			</van-list>
		</van-pull-refresh>
		<van-image-preview v-model="showImgPopup" :images="imageList" @change="onChangePreview">
			<template v-slot:imgIndex>第{{ imgIndex+1 }}页</template>
		</van-image-preview>
		<div class="page-bottom" v-if="detailData.status === 1">
			<div class="img-uploader">
				<i />
				<van-uploader :before-read="beforeRead" :after-read="afterRead" accept="image/*" class="pic-uploader" :max-count="1" />
			</div>
			<van-field type="textarea" rows="1" :autosize="{maxHeight: 70}" @input="onTextChange" class="text-input" v-model="text" placeholder="病情描述，一次最多输入200字" />
			<van-button :disabled="btnDisabled || !text" class="send-btn" @click="handleSendText">发送</van-button>
		</div>
	</div>
</template>
<script>
import { consultationDetail, conversationsDetail, conversationsHistoryList, sendToDoctor, getOssToken } from '@/request/api/user';
// uploadFile
import { doctorDetail } from '@/request/api/doctor';
// import { mapGetters } from 'vuex';
import { dateFormat, getTimeInfo } from '@/utils/time';
import { cutdownString } from '@/utils';
import OSS from 'ali-oss';
import md5 from 'md5';
// import axios from 'axios';

export default {
	props: {
		soughtType: String
	},
	data () {
		return {
			text: '',
			loadText: '加载中...',
			btnDisabled: true,
			imgList: [],
			dataList: [],
			newImMsg: {},
			conversationId: '',
			currentPage: 1,
			totalPage: 0,
			pageSize: 10,
			showImgPopup: false,
			imgIndex: 0,
			imageList: [],
			loading: false,
			finished: false,
			refreshing: false,
			userInfo: {},
			detailData: {},
			timer: null,
			timeStr: '',
			doctor: {}
		};
	},
	created () {
		this.conversationId = this.$route.query.conversationId;
		this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
		this.getDetail();
		this.getHistory();
		this.getDoctorDetail();
	},
	watch: {
		'$store.state.main.newImMsg': {
			handler: function (val) {
				this.newImMsg = val;
				if (val && val.conversationId) {
					if (val.conversationId === this.conversationId) {
						// console.log('收到了新消息！！', val);
						const item = {
							from: val.from,
							to: val.to,
							createdAt: val.createdAt,
							MsgContent: {
								MsgType: val.MsgType,
								MsgContent: val.MsgContent
							}
						};
						this.dataList = [...this.dataList, item];
						if (this.detailData.status === 1) {
							const statusData = { ...val.conversationStatus };
							if (statusData.type === 12 && item.from !== this.userInfo.mobilePhone) {
								statusData.rounds--;
							}
							this.detailData = { ...statusData };
							if (this.detailData.type === 11) {
								this.loopTime(this.detailData.deadline);
							}
						}
						const that = this;
						setTimeout(() => {
							that.$refs.msgList.$el.scrollTo(0, that.$refs.msgList.$el.scrollHeight);
							// that.$refs.msgList.scrollTo(0, that.$refs.msgList.scrollHeight);
						}, 500);
					}
				}
			},
			deep: true,
			immediate: true
		}
	},
	destroyed () {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
	},
	mounted () {
		const msgList = this.$refs.msgList.$el;
		setTimeout(() => {
			this.scrollHeight = msgList.scrollHeight;
			msgList.scrollTo(0, this.scrollHeight);
		}, 500);
	},
	methods: {
		cutdownStr (str, length) {
			return cutdownString(str, length);
		},
		loopTime (dateStr = '') {
			if (this.timer) {
				clearInterval(this.timer);
				this.timer = null;
			}
			this.timer = setInterval(() => {
				this.getCountDown(dateStr);
			}, 1000);
		},
		getCountDown (dateStr = '') {
			const time = new Date(dateStr).getTime();
			const currTime = new Date().getTime();
			if (time >= currTime) {
				const json = getTimeInfo(time - currTime);
				this.timeStr = `${json.d ? json.d + '天' : ''}${(json.h < 10 ? '0' : '') + json.h}:${(json.m < 10 ? '0' : '') + json.m}:${(json.s < 10 ? '0' : '') + json.s}`;
			} else {
				clearInterval(this.timer);
				this.detailData.status = 0;
				// this.text = '问诊结束';
				// this.handleSendText();
			}
		},
		setTitle () {
			document.title = `${this.doctor.name} ${this.doctor.deptName}`;
		},
		getDoctorDetail () {
			doctorDetail({ id: this.$route.query.doctorId }).then(data => {
				if (data && data === 'retry') {
					this.getDoctorDetail();
				} else if (data) {
					this.doctor = data;
					this.setTitle();
				} else {
					this.doctor = {};
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		getDetail () {
			conversationsDetail({ conversationId: this.conversationId }).then(data => {
				if (data && data === 'retry') {
					this.getDetail();
				} else if (data) {
					this.detailData = { ...data };
					if (data.type === 11 && data.status === 1) {
						this.loopTime(data.deadline);
					}
				}
			});
		},
		onRefresh () {
			if (!this.finished) {
				this.loading = true;
				this.onLoad();
			} else {
				this.refreshing = false;
			}
		},
		onLoad () {
			this.getHistory(10);
		},
		formatDate (date) {
			return dateFormat(new Date(date), 'yyyy-MM-dd hh:mm');
		},
		getHistory (pageSize, type) {
			this.loading = true;
			conversationsHistoryList({
				conversationId: this.conversationId,
				currentPage: this.currentPage,
				pageSize
			}).then(data => {
				// console.log('data====', data)
				if (data && data === 'retry') {
					this.getHistory(pageSize, type);
				} else if (data && Object.keys(data.items).length) { // && Object.keys(data.items).length
					this.loading = false;
					this.currentPage = Number(data.pageInfo.currentPage);
					this.totalPage = data.pageInfo.totalPage;
					for (const item of data.items) {
						if (!item.MsgContent) item.MsgContent = {};
					}
					if (type) {
						const that = this;
						this.dataList = [...this.dataList, ...data.items];
						setTimeout(() => {
							that.$refs.msgList.$el.scrollTo(0, that.$refs.msgList.$el.scrollHeight);
							// that.$refs.msgList.scrollTo(0, that.$refs.msgList.scrollHeight);
						}, 500);
					} else {
						this.dataList = [...data.items.reverse(), ...this.dataList];
						const json = {};
						for (const item of this.dataList) {
							json[item.id] = item;
						}
						const array = [];
						for (const key in json) {
							array.push(json[key]);
						}
						this.dataList = [...array];
					}
					if (this.currentPage === 1) {
						setTimeout(() => {
							this.$refs.msgList.$el.scrollTo(0, this.$refs.msgList.$el.scrollHeight);
							// this.$refs.msgList.scrollTo(0, this.$refs.msgList.scrollHeight);
						}, 500);
					} else if (this.currentPage <= data.pageInfo.totalPage) {
						setTimeout(() => {
							this.$refs.msgList.$el.scrollTo(0, this.$refs.msgList.$el.scrollHeight - this.scrollHeight);
							this.scrollHeight = this.$refs.msgList.$el.scrollHeight;
							// this.$refs.msgList.scrollTo(0, this.$refs.msgList.scrollHeight);
						}, 50);
					}
					if (this.currentPage === data.pageInfo.totalPage) {
						this.finished = true;
					} else {
						this.currentPage++;
					}
				} else {
					this.finished = true;
				}
				this.refreshing = false;
			}).catch(e => {
				this.loading = false;
				this.$root.elMsg(e.message, 'fail');
			});
		},
		onTextChange () {
			this.btnDisabled = true;
			if (this.text) this.btnDisabled = false;
			if (this.text.length > 200) this.text = this.text.substr(0, 200);
		},
		handelClickImg (url) {
			this.showImgPopup = true;
			this.imageList = [url];
		},
		onChangePreview (index) {
			this.imgIndex = index;
		},
		onError (e) {
			// console.log('e======', e);
		},
		beforeRead (file) {
			if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
				return true;
			} else {
				this.$toast('请上传jpg或png格式的图片');
				return false;
			}
		},
		afterRead (file, name) {
			file.status = 'uploading';
			file.message = '上传中';
			getOssToken().then(data => {
				const imgType = file.file.type.split('/')[1];
				const filename = file.file.name + file.file.size; // md5对图片名称进行加密
				const keyValue = '' + md5(new Date() + filename) + '.' + imgType;
				const client = new OSS({
					region: data.region,
					// secure: true,
					stsToken: data.SecurityToken,
					accessKeyId: data.AccessKeyId,
					accessKeySecret: data.AccessKeySecret,
					bucket: data.bucket
				});
				client
					.put('odat/im/' + this.conversationId + '/' + keyValue, file.file)
					// .multipartUpload(keyValue, file.file)
					.then((res) => {
						if (res.url) {
							file.url = res.url;
							file.status = 'uploaded';
							file.message = '';
							this.handleSendImg(file.url);
						} else {
							file.status = 'failed';
							this.$toast('图片上传失败');
						}
					})
					.catch((err) => {
						console.log('message', err.message);
						file.status = 'failed';
						this.$toast('图片上传失败');
					});
			});
		},
		sendMsg (msg = {}, cb) {
			sendToDoctor(msg).then(data => {
				if (data && data === 'retry') {
					this.sendMsg(msg, cb);
				} else if (data) { // && data.ActionStatus === 'OK'
					cb(true);
				} else {
					cb(false);
				}
			});
		},
		handleSendImg (url) {
			const msg = {
				conversationId: this.conversationId,
				body: {
					MsgType: 'TIMImageElem',
					conversationId: this.conversationId,
					MsgContent: {
						Url: url
					}
				}
			};
			this.sendMsg(msg, (result) => {
				if (result) {
					// this.text = '';
				}
			});
		},
		handleSendText () {
			this.btnDisabled = true;
			const msg = {
				conversationId: this.conversationId,
				body: {
					MsgType: 'TIMTextElem',
					conversationId: this.conversationId,
					MsgContent: {
						Text: this.text
					}
				}
			};
			this.sendMsg(msg, (result) => {
				this.text = '';
				if (result) {
					this.text = '';
				} else {
					this.btnDisabled = false;
				}
			});
		},
		handleConsultation (item) {
			consultationDetail({ doctorId: item.doctorId, patientId: item.patientId, id: item.MsgContent.MsgContent.data.consultationId }).then(data => {
				if (data && data === 'retry') {
					this.handleConsultation(item);
				} else if (data) {
					let qa = {};
					if (data.consultationAnswer) {
						qa = JSON.parse(data.consultationAnswer);
					}
					this.$store.commit('setStep', {
						step: 'stepTwo',
						obj: { qa: qa, consultationType: data.consultationType }
					});
					this.$store.commit('setStep', {
						step: 'stepConsultation',
						obj: { consultation: data }
					});
					this.$root.go('/consultation-pay', {
						doctorId: data.doctorId,
						patientId: data.patientId,
						subType: data.type,
						id: data.id,
						doctorName: this.doctor.name,
						departmentName: this.doctor.deptName,
						departmentId: this.doctor.departmentId
					});
				}
			});
		},
		handlePrescription (prescriptionId) {
			this.$root.go(`/prescription-detail?id=${prescriptionId}&conversationId=${this.conversationId}&doctorId=${this.$route.query.doctorId}&at=2&patientId=${this.$route.query.patientId}`);
		}
	}
};
</script>
<style lang="less">
	.van-overlay.van-image-preview__overlay {
		z-index: 999;
		background-color: rgba(0,0,0,.7);
	}
</style>
<style lang="less" scoped>
	.msg-content {
		display: flex;
		flex-direction: column;
		padding: 0;
		overflow: hidden;;
		background-color: #f5f5f5;
		flex: 1;
		position: relative;
	}
	.content-top {
		background-color: #FFFFFF;
		padding: 14px 0;
		display: flex;
		// position: absolute;
		// top: 0;
		// left: 0;
		// right: 0;
		align-items: center;
		i {
			width: 7px;
			height: 7px;
			background: #36BF25;
			border-radius: 100%;
			margin-left: 15px;
		}
		span.status {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #232830;
			line-height: 13px;
			margin-left: 6px;
		}
		span:last-child {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #959595;
			line-height: 13px;
			margin-left: 8px;
		}
	}
	.msg-list {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
		// margin-bottom: 10px;
		// padding-bottom: 10px;
		// padding-top: 10px;
		// margin-top: 40px;
		// margin-bottom: 60px;
		overflow: scroll;
		.my-msg {
			display: flex;
			justify-content: flex-end;
			margin-top: 14px;
			.my-i {
				background: url('../../assets/images/patient-m.png') no-repeat;
				background-size: contain;
			}
			.consultation {
				width: 241px;
				// flex: 1;
				// margin-left: 70px;
				min-height: 128px;
				background: #FFFFFF;
				border-radius: 10px 2px 10px 10px;
				margin-right: 10px;
				.consultation-top {
					display: flex;
					align-items: center;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
					font-size: 15px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 22px;
					border-top-left-radius: 10px;
					padding: 8px 0;
					i {
						width: 1px;
						height: 8px;
						background: #FFFFFF;
						border-radius: 0px;
						opacity: 0.29;
						margin-left: 10px;
					}
					span {
						margin-left: 10px;
					}
					span:first-child {
						margin-left: 14px;
					}
				}
				.consultation-bottom {
					display: flex;
					flex-direction: column;
					padding-bottom: 10px;
					.consultation-bottom-center {
						flex: 1;
						border-bottom: 1px solid #f0f0f0;
						margin: 0 12px 8px 12px;
						padding: 12px 2px 14px 2px;
						span {
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #959595;
							line-height: 12px;
						}
						div {
							display: flex;
							span {
								font-size: 13px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #2E2D2D;
								line-height: 16px;
								margin-top: 8px;
								width: 100%;
								word-break: break-word;
								white-space: pre-wrap;
							}
						}
					}
				}
			}
		}
		.other-msg {
			display: flex;
			justify-content: flex-start;
			margin-top: 14px;
			.doctor-i {
				background: url('../../assets/images/doctor-def.png') no-repeat;
				background-size: contain;
			}
			.prescription {
				width: 241px;
				// flex: 1;
				// margin-right: 70px;
				height: 94px;
				background: #FFFFFF;
				border-radius: 2px 10px 10px 10px;
				display: flex;
				flex-direction: column;
				margin-left: 10px;
				.prescription-top {
					background: url('../../assets/images/prescription_bg.png') no-repeat;
					flex: 2;
					background-size: 100% 100%;
					display: flex;
					align-items: center;
					position: relative;
					i.disease-icon {
						width: 16px;
						height: 19px;
						background: url('../../assets/images/prescription_icon.png') no-repeat;
						background-size: 100% 100%;
						margin-left: 14px;
					}
					span {
						font-size: 15px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 22px;
						margin-left: 6px;
					}
					i.status-icon-1 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_1.png') no-repeat;
						background-size: 100% 100%;
					}
					i.status-icon-2 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_2.png') no-repeat;
						background-size: 100% 100%;
					}
					i.status-icon-3 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_3.png') no-repeat;
						background-size: 100% 100%;
					}
					i.status-icon-4 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_4.png') no-repeat;
						background-size: 100% 100%;
					}
					i.status-icon-5 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_5.png') no-repeat;
						background-size: 100% 100%;
					}
					i.status-icon-6 {
						width: 45px;
						height: 45px;
						position: absolute;
						right: 0;
						top: 0;
						background: url('../../assets/images/prescription_status_6.png') no-repeat;
						background-size: 100% 100%;
					}
				}
			}
		}
		.msg-item {
			.time {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 34px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #959595;
				line-height: 12px;
				letter-spacing: 0px;
			}
		}
		.msg-item:last-child {
			margin-bottom: 10px;
		}
		.avatar {
			width: 34px;
			height: 34px;
			background: #909090;
			border-radius: 100%;
			margin-right: 10px;
		}
		.other-msg i {
			margin-left: 10px;
			margin-right: 0;
		}
		.message-content {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #232830;
			line-height: 20px;
			background-color: #ffffff;
			padding: 10px;
			max-width: 237px;
			// max-width: 100%;
			// margin-left: 70px;
			// flex: 1;
			border-radius: 10px 2px 10px 10px;
			margin-right: 10px;
		}
		.other-msg .message-content {
			margin-left: 10px;
			margin-right: 70px;
			background: #FF6450;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			border-radius: 10px;
			border-top-left-radius: 2px;
			.img-content {
				background-color: rgba(245, 245, 245, .7);
				max-width: 237px;
				img {
					width: 100%;
				}
			}
		}
	}

	.prescription-bottom,
	.other-msg .prescription-bottom {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
			line-height: 12px;
			margin-left: 14px;
		}
		i {
			width: 5px;
			height: 9px;
			background: url('../../assets/images/right-arrow.png') no-repeat;
			background-size: 100% 100%;
			margin-right: 11px;
		}
	}

	.other-msg .message-content.img-content {
		background: #dedede;
	}
	.other-msg .message-content.sound-content {
		background: #dedede;
		audio {
			display: block !important;
			width: 200px;
		}
	}
	.page-bottom {
		// position: fixed;
		z-index: 1;
		display: flex;
		// position: fixed;
		bottom: 0;
		align-items: center;
		justify-content: space-between;
		background-color: #ffffff;
		padding: 8px 0;
		width: 100%;
		.img-uploader {
			position: relative;
			padding: 0 11px 0 14px;
			i {
				width: 25px;
				height: 25px;
				display: block;
				background-image: url('../../assets/images/send-img-icon.png');
				background-size: 100% 100%;
			}
			.pic-uploader {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 0;
				margin: 0;
			}
		}
		.text-input {
			flex: 1;
			background: #F5F5F5;
			border-radius: 18px;
			margin-right: 9px;
			padding: 9px 14px;
		}
		.send-btn {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 17px;
			font-size: 13px;
			font-weight: 400;
			font-family: PingFangSC-Regular, PingFang SC;
			color: #FFFFFF;
			line-height: 18px;
			margin-right: 12px;
			padding: 9px 9px 7px 9px;
			border: none;
			height: auto;
		}
	}
	.loadText {
		text-align: center;
		font-size: 11px;
		font-weight: 400;
		color: #959595;
		margin-top: 10px;
		font-family: PingFangSC-Regular, PingFang SC;
	}
</style>