<template>
	<div class="content">
		<ul class="tab-c flex f-jc-sa f-ai-c">
			<li :class="activeTab === 1 ? 'active' : ''" @click="tabClick(1)">医生信息</li>
			<li :class="activeTab === 2 ? 'active' : ''" @click="tabClick(2)">问诊对话</li>
			<li :class="activeTab === 3 ? 'active' : ''" @click="tabClick(3)">我的病历</li>
		</ul>
		<div :style="{ overflow: activeTab === 2 ? 'hidden' : 'auto' }">
			<doctor-detail @refresh="tabClick(2)" v-if="activeTab === 1"></doctor-detail>
			<s-im v-if="activeTab === 2" :soughtType="soughtType"></s-im>
			<medical-record v-if="activeTab === 3"></medical-record>
		</div>
	</div>
</template>
<script>
import doctorDetail from '@/views/doctor/detail.vue';
import sIm from '@/views/patient/im.vue';
import medicalRecord from '@/views/patient/medicalRecord.vue';
export default {
	name: 'dis',
	data () {
		return {
			activeTab: 1,
			patientId: 0,
			doctorId: 0,
			soughtType: '' // 服务类型
		};
	},
	components: {
		doctorDetail,
		sIm,
		medicalRecord
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	created () {
		const _at = this.$route.query;
		if (_at.at) {
			this.activeTab = Number(_at.at);
		}
		this.doctorId = Number(_at.doctorId);
	},
	methods: {
		onBack () {
			this.$root.go('my-doctor');
		},
		tabClick (tab) {
			this.activeTab = tab;
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		height: 100%;
		flex-direction: column;
		display: flex;
		overflow: hidden;
	}
	.content>div {
		// margin-top: 44px;
		flex: 1;
		flex-direction: column;
		display: flex;
	}
	.tab-c {
		// width: 375px;
		height: 44px;
		background: #FFFFFF;
		// margin-bottom: 10px;
		width: 100%;
		// position: fixed;
    z-index: 1;
		top: 0;
		li {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
			position: relative;
			height: 100%;
			line-height: 44px;
			&.active {
				color: #FF6450 !important;
				font-weight: 600;
				&::after {
					position: absolute;
					content: '';
					background-color: #FF6450;
					width: 1.875rem;
					height: 0.125rem;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
</style>